import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from './components/Dashboard';
import Login from "./initials/Login";
import Profile from "./components/Profile";
import Register from "./initials/Register";
import User from "./components/User";
import Participant from "./components/Participant";
import Resultat from "./components/Resultat";
import Notfound from "./initials/Notfound";
import Profil from "./components/Profil";
 
function RouterPage(){

    return(
        <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route  path="/profile" component={ Profil } />
            <Route  path="/profiles" component={ Profile } />
            <Route  path="/users" component={ User } />
            <Route  path="/applications" component={ Participant } />
            <Route  path="/results" component={ Resultat } />
            <Route  path="/login" component={ Login } />
            <Route  path="/register" component={ Register } />
            <Route component={Notfound} />
        </Switch>
    )
}

export default RouterPage;