import axios from "axios";
import React, {Component} from "react";
import UserForm from "./UserForm";
import { Link } from "react-router-dom";
import Profile from "./Profile";

class User extends Component{

    state = {
        isModal : false,
        formData : null,
        users : [],
    }

    componentDidMount(){
        this.getUsers();
    }

    async getUsers(){
        

        
        var users = await axios.get('users');
        if(users.status === 200)
        {
            this.setState({users : users.data})
        }
    }

    showModal = (user = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : user,
        })
        this.getUsers()
    }

    delete = (user) =>{
        axios.delete("users/" + user).then((response) => {
            this.getUsers()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions"));
        return (permissions ?? []).some(item => val === item.name)
    }

    render(){
        var users =
        this.state.users.map((user, count) => {
                return(

                    <div className="col-md-3 col-6 overflow-hidden" key={user.id}>
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body pb-0">
                            
                            <Link to={'#'}  className="text-decoration-none">
                                <img src={ user.avatar } className="img-thumbnail"  width="307" height="204" />
                            </Link>
                            <div className="ms-2 mt-2 text-truncate">
                                <div>{  user.name  }</div>
                                <div><a href={"tel:"+  user.tel  }  target="_blank" className="text-dark text-decoration-none me-2"> {  user.tel  }</a> <br />
                                 <a href={"mailto:"+ user.email }  target="_blank" className="text-dark text-capitalize text-decoration-none"> { user.email}</a> </div>
                                <div><a href="#" className="text-dark text-capitalize text-decoration-none">  { user.adress}</a> </div>
                            </div>
                                
                            <div className="float-end">
                            {(this.handleCheck("modifier personnel") == true) && (
                            <a href="#" onClick={ () => this.showModal(user)}><i className="ti ti-edit text-primary font-22 me-3" ></i></a>
                            )}

                            {(this.handleCheck("supprimer personnel") == true) && (
                                <a href="#" onClick={() => this.delete(user.id)}><i className="ti ti-trash text-danger font-22"></i></a>
                            )}
                            </div>   
                        </div>
                    </div>
                </div>
                

                )
        })
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          <h4 className="page-title">Utilisteur </h4>
                          </div>
                              {
                                  this.handleCheck("ajouter society") != true && (
                                      <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouveau utilisateur</button>
                                      </div>
                                  )
                              }
                      </div>

                </div>
                <div className="col-12">
                    <Profile />
                    <div className="card-body row">
                            
                        { users }
                                
                    </div>
                </div>

              </div>

            <UserForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default User;
