import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";

class   UserForm extends Component{

    state = {
        id : null,
        profileId : null,
        name : "",
        email : "",
        tel : "",
        password : "",
        adress : "",
        avatar : "",
        error : "",

        title : "",
        profiles : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getprofiles () {
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
        
      var profiles = await axios.get('profiles');
        
        if(profiles.status === 200)
        {
            this.setState({profiles : profiles.data })
        }
    }

    storeUser = (e) =>{
        e.preventDefault();

        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var data = {
                profileId : this.state.profileId ? this.state.profileId : null,
                name : this.state.name,
                email : this.state.email ? this.state.email : null,
                tel : this.state.tel ? this.state.tel : null,
                password : "123",
                adress : this.state.adress,
                avatar : this.state.id ? this.state.avatar : "https://ui-avatars.com/api/?name=" + this.state.name + "&color=7F9CF5&background=EBF4FF",
        }

        var request = this.state.id ? axios.put('users/'+this.state.id, data) : axios.post('users', data);
        request.then((response) =>{
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
        })
    }

    edit=()=>  {
        this.getprofiles();
        this.form()
    }

    form=()=>{

        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            profileId: this.props.formData ? this.props.formData.userprofile ? this.props.formData.userprofile.profile ? this.props.formData.userprofile.profile.id : "" : "" : "",
            name: this.props.formData ? this.props.formData.name : "",
            email: this.props.formData ? this.props.formData.email : "",
            tel: this.props.formData ? this.props.formData.tel : "",
            type: this.props.formData ? this.props.formData.type : "",
            adress: this.props.formData ? this.props.formData.adress : "",
            title : this.props.formData ? "Modifier l'utilisateur" : "Ajouter l'utilisateur",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.storeUser}>
                <Modal.Body className="row">
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Nom</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Email</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="email"  value={this.state.email} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Téléphone</label>
                            <div className="position-relative mt-1">
                                <input type="number" className="form-control"  name="tel"  placeholder="" onChange={this.handleInput} value={this.state.tel} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Adresse</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="adress"  value={this.state.adress} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Profil</label>
                                <div className="position-relative mt-1">
                                    <select id="profileId" name="profileId" required={true}  value={this.state.profileId} onChange={this.handleInput} className="form-select">
                                        <option value="">...</option>
                                        {
                                            this.state.profiles.map((profile) => {
                                                return(
                                                    <option value={ profile.id}>{ profile.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                    <button type="submit" className="btn btn-sm btn-outline-primary"><i className="ti ti-check-square"></i> Sauvegarder</button>
                </Modal.Footer>
                </form>
            </Modal>


        </div>
        )
    }
}
export default UserForm;
