import axios from "axios";
import React, {Component} from "react";
import Login from "./Login";

class Register extends Component {

    state = {
        name : "",
        tel : "",
        password : "",
        confPassword : "",
        error : "",
        click : false,
        login : false
    }
    
    componentDidMount(){
        window.localStorage.clear();
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    createLogin = () =>{
        this.setState({ login : !this.state.login})
    }

    register  = () => {
        
        this.setState({
            click : true,
            error : ""
        })
        if(this.state.password == this.state.confPassword)
        {
            axios.post('register', {
                name: this.state.name,
                tel: this.state.tel,
                email: this.state.email,
                password : this.state.password,
                conf_password : this.state.confPassword,
                avatar : "https://ui-avatars.com/api/?name=" + this.state.name + "&color=7F9CF5&background=EBF4FF",
            }).then((login) => {
                var user = (login.data.data);
                localStorage.setItem("user", JSON.stringify(user));
                window.location.replace(`https://salondigital.fondationmvura.org`)
            }).catch((error) => {
                window.localStorage.clear();
                this.setState({
                    error: error.response.data.msg,
                });
            })
        }
        else{
            this.setState({
                error:  "Mot de passes ne correspondent pas"
            });
        }
    }

    render(){
        return(
            <div>
                {
                    this.state.login && (<Login />)
                }
                {  !this.state.login && (
                    <div id="body" className="auth-page">
                     
                        <div className="container-md">
                            <div className="row vh-100 d-flex justify-content-center">
                                <div className="col-12 align-self-center">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4 mx-auto">
                                                <div className="card shadow mb-4 rounded rounded-3">
                                                    <img src="/photo/backgrnd.jpeg" className="" />
                                                    <div className="card-body pt-0">                                    
                                                        <form className="my-4" onSubmit={this.register}>  
                                                            {this.state.error && (<div className="alert alert-danger text-center text-danger py-1 px-2 text-small">{this.state.error}</div>)}          
                                                            <div className="form-group mb-2">
                                                                <label className="form-label" htmlFor="name">Nom</label>
                                                                <input type="text" className="form-control" id="name" name="name" onChange={this.handleInput} placeholder="" />                               
                                                            </div>

                                                            <div className="form-group mb-2">
                                                                <label className="form-label" htmlFor="tel">Téléphone</label>
                                                                <input type="number" className="form-control" id="tel" name="tel"  onChange={this.handleInput} placeholder="" />                               
                                                            </div>

                                                            <div className="form-group mb-2">
                                                                <label className="form-label" htmlFor="email">Email</label>
                                                                <input type="email" className="form-control" id="email" name="email"  onChange={this.handleInput} placeholder="" />                               
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="userpassword">Mot de passe</label>                                            
                                                                <input type="password" className="form-control" name="password" onChange={this.handleInput} id="userpassword" placeholder="" />                            
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="confPassword">Confirmer votre mot de passe</label>                                            
                                                                <input type="password" className="form-control" name="confPassword" onChange={this.handleInput} id="confPassword" placeholder="" />                            
                                                            </div>
                                
                                                            <div className="form-group mb-0 row">
                                                                <div className="col-12">
                                                                    <div className="d-grid mt-3">
                                                                        <button className="btn btn-primary" type="button" onClick={()=>this.register()}>Enregister <i className="fas fa-sign-in-alt ms-1"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>                           
                                                        
                                                        </form>
                                                        
                                                        
                                                        <hr className="hr-dashed mt-4" />
                                                        <div className="text-center mt-n5">
                                                            <h6 className="card-bg px-3 my-4 d-inline-block" type="button" onClick={() => this.createLogin()}>Je me suis inscrit</h6>
                                                        </div>
                                                         
                                                    </div>{/*end card-body*/}
                                                </div>{/*end card*/}
                                            </div>{/*end col*/}
                                        </div>{/*end row*/}
                                    </div>{/*end card-body*/}
                                </div>{/*end col*/}
                            </div>{/*end row*/}
                        </div>{/*end container*/}

                        {/* App js */}
                        
                    </div>
                )}
            </div>
        )
    }
}
export default Register