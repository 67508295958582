import axios from "axios";
import React, {Component} from "react";
import ProfileForm from "./ProfileForm";

class Profile extends Component{

    state = {
        isModal : false,
        formData : null,
        profiles : [],
    }

    componentDidMount(){
        this.getProfiles();
    }

    async getProfiles(){
         
        var profiles = await axios.get('profiles/');
        
        if(profiles.status === 200)
        {
            this.setState({profiles : profiles.data})
        }
    }

    showModal = (profile = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : profile,
        })
        this.getProfiles()
    }


    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions"));
        return (permissions ?? []).some(item => val === item.name)
    }

    render(){
        var  profiles =
        this.state.profiles.map((profile) => {
            return(
                <span className="border border-1 me-3 rounded rounded-3 px-1">
                    { profile.name }
                    {
                        this.handleCheck("modifier profil") == true && (
                        <a href="#" onClick={ () => this.showModal(profile)}><i className="ti ti-edit text-primary font-15 mx-1" ></i></a>
                    )}
                </span>
            )
        })
        return(
          <div className="mt-2">
          
                     { profiles}
                {
                    this.handleCheck("ajouter profil") == true && (
                        
                            <button type="button" className="btn btn-sm  font-15 btn-sm" onClick={() => this.showModal(null)}><i className="ti ti-plus font-16"></i></button>
                        
                    )
                }

              <ProfileForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Profile;
