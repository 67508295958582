import React, {Component} from "react";

class Notfound extends Component{

    render(){
        return(
            <div id="body" className="auth-page">
    
                <div className="container-md">
                    <div className="row vh-100 d-flex justify-content-center">
                        <div className="col-12 align-self-center">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="card shadow mb-4 rounded rounded-3">
                                            <img src="/photo/backgrnd.jpeg" className="" />
                                            
                                            <div className="card-body">
                                                <div className="ex-page-content text-center">
                                                    <h4 className="mt-3 mb-1 fw-semibold font-18">Page non trouvée</h4>
                                                    <h1 className="mt-5 mb-4">404</h1>                                  
                                                </div>          
                                                <a className="btn btn-primary w-100" href="/">Revenir à la la page d'accueil. <i className="fas fa-redo ml-1"></i></a>                         
                                            </div>
                                            <div className="card-body bg-light-alt text-center">
                                                &copy; <b>SalonDigital</b>                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Notfound;