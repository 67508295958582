import React, {Component} from "react";

class Resultat extends Component{

    render(){

        return(
          <div>
             Resultat

      </div>
        )
    }
}
export default Resultat;
