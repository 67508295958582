import React, {Component} from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class Profil extends Component{

    state = {
        id :  null,
        loadingForm : false,
        dataImage : "",
        image : [],
        password : "",
        confirmed : "",
        name : "",
        email : "",
        tel : "",
        adress : "",
        gender : "",
        date_of_birth : "",
        error : "",
        error1 : "",
        errorImg : "",
        valid : "",
        user : {},
        request_id : "",
        project_title : "",
        project_impact : "",
        programming_language : "",
        frameworks_backend : "",
        Frameworks_frontend : "",
        github : "",
        cloud : "",
        comment : "",
        status : "",
        btn : false,
    }

    componentDidMount(){
        this.user();
        this.getUser();
    }

    async user(){
        
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;
        
        if(user){
            
        var startDate = new Date(user.date_of_birth);
        var mois = startDate.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        var jour = startDate.getDate() >= 10 ? startDate.getDate() : "0"+startDate.getDate();
        startDate = startDate.getFullYear()  + "-" + (mois) + "-" + jour;

            this.setState({ 
                id : user.id,
                name : user.name,
                avatar : user.avatar,
                tel : user.tel,
                email : user.email,
                adress : user.adress,
                gender : user.gender,
                date_of_birth : startDate,
            })
        }
    }

    async getUser(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var data = await axios.get("users/" + user.id);
        if(data.status === 200){
            var startDate = new Date(data.data.date_of_birth);
            var mois = startDate.getMonth()+1;
                mois = mois >= 10 ? mois : "0"+mois;
            var jour = startDate.getDate() >= 10 ? startDate.getDate() : "0"+startDate.getDate();
            startDate = startDate.getFullYear()  + "-" + (mois) + "-" + jour;
        
            console.log(data);

            this.setState({
                user : data.data,
                id : data.data.id,
                name : data.data.name,
                avatar : data.data.avatar,
                tel : data.data.tel,
                email : data.data.email,
                adress : data.data.adress,
                gender : data.data.gender,
                date_of_birth : startDate,

                request_id : data.data.request.id,
                project_title : data.data.request.project_title,
                project_impact : data.data.request.project_impact,
                programming_language : data.data.request.programming_language,
                frameworks_backend : data.data.request.frameworks_backend,
                Frameworks_frontend : data.data.request.Frameworks_frontend,
                github : data.data.request.github,
                cloud : data.data.request.cloud,
                comment : data.data.request.comment,
                status : data.data.request.status,
            });
        }
    }

    handleInputFile = (e) => {

        this.setState({
            errorImg : "",
            loadingForm : false,
            dataImage : e.target.files[0],
        })
    }


    async refreshToken () {
        try{
            var token = await axios.get('token');
            if(token.status == 200)
            {
                var user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
                this.setState({
                    avatar : user.avatar
                });
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(`https://salondigital.fondationmvura.org/login`)
            }
        }catch(error) {
            console.log(error)
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(`https://salondigital.fondationmvura.org/login`)
        }
    }

    storeImage = async (e) => {
        e.preventDefault()
        this.setState({
            error:  "",
            error1:  "",
            errorImg : "",
            btn : !this.state.btn
        })

        let formData = new FormData()
        formData.append('file', this.state.dataImage)
        formData.append('user', this.state.id)

        const response = await fetch('https://apisalondigital.herokuapp.com/avatars', {
            method: "POST",
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                errorImg : "saved image",
                btn : !this.state.btn
            })
            this.refreshToken();
        }
        else{
            this.setState({
                errorImg : "error",
                btn : !this.state.btn
            })
        }
    }

    storeProfile = () =>{

        if(this.state.password == this.state.confirmed)
        {
            this.setState({
                error:  "",
                error1:  "",
                btn : !this.state.btn
            });

            var data = {
                    name : this.state.name,
                    email : this.state.email ? this.state.email : null,
                    tel : this.state.tel ? this.state.tel : null,
                    type : this.state.user ? this.state.user.type : null,
                    password : this.state.password ? this.state.password : null,
                    adress : this.state.adress,
                    gender : this.state.gender,
                    date_of_birth : this.state.date_of_birth
                }
 
            var request = this.state.id ? axios.put('users/'+this.state.id, data) : axios.post('users', data);
            request.then((response) =>{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                
                axios.post("login", {
                    username: this.state.tel > 0 ? this.state.tel : this.state.email,
                    password: this.state.password
                }).then((login) => {
                    localStorage.setItem("user", JSON.stringify(login.data.data));
                    this.setState({ 
                        id : login.data.data.id,
                        name : login.data.data.name,
                        avatar : login.data.data.avatar,
                        tel : login.data.data.tel,
                        email : login.data.data.email,
                        adress : login.data.data.adress,
                        gender : login.data.data.gender,
                        date_of_birth : login.data.data.date_of_birth,
                        error : "success",
                        btn : !this.state.btn
                    })
                }).catch((error) => {
                    window.localStorage.clear();
                    this.setState({
                        error: error.response.data.msg,
                        btn : !this.state.btn
                    });
                });
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
            })
        }
        else{
            this.setState({
                error:  "Mot de passes differents",
            });
        }
    }

    storeApplication = () => {
        var data = {
            project_title : this.state.project_title,
            project_impact : this.state.project_impact,
            programming_language : this.state.programming_language,
            frameworks_backend : this.state.frameworks_backend,
            Frameworks_frontend : this.state.Frameworks_frontend,
            github : this.state.github,
            cloud : this.state.cloud,
            userId : this.state.id,
        }

        this.setState({
            btn : !this.state.btn,
            error:  "",
            error1:  "",

        })
 
        var request = this.state.request_id ? axios.put('requests/'+this.state.id, data) : axios.post('requests', data);
            request.then((response) =>{
                this.setState({
                    // project_title : response.data.project_title,
                    // project_impact : response.data.project_impact,
                    // programming_language : response.data.programming_language,
                    // frameworks_backend : response.data.frameworks_backend,
                    // Frameworks_frontend : response.data.Frameworks_frontend,
                    // github : response.data.github,
                    // cloud : response.data.cloud,
                    error1 : "success",
                    btn : !this.state.btn
                })
            }).catch((error) => {
                this.setState({
                    error1 : error.response.data.msg,
                    btn : !this.state.btn
                })
            })
    }
    

    hundelInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        
        return(
            <div className="row">
                <h4 className="h4 font-monospace">Profil</h4>
                <div className="col-md-7 col">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body d-flex">
                            <div className="image mr-3">
                                <Link to={'#'}  className="text-decoration-none">
                                    <img src={ this.state.avatar } className="rounded-circle img-fluid me-2" width={(  this.state.avatar) && ("100px")} />
                                </Link>
                            </div>
                            <div>
                                <div>{  this.state.name  }</div>
                                <div><a href={"tel:"+  this.state.tel  } target="_blank" className="text-dark text-decoration-none">  {  this.state.tel  }</a> </div>
                                <div><a href={"mailto:"+ this.state.email } target="_blank" className="text-dark text-capitalize text-decoration-none">  { this.state.email}</a> </div>
                                <div><a href="#"   className="text-dark text-capitalize text-decoration-none">  { this.state.adress}</a> </div>
                                <div className="text-center m-1">
                                    <span className="small rounded-3 border px-1 mx-1">{ this.state.gender }</span>
                                    <span  className=" small rounded-3 mx-1" type="button">{  this.state.user.userprofile ? this.state.user.userprofile.profile ? this.state.user.userprofile.profile.name : "" : "" }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-5">
                    <div className="card shadow overflow-hidden mb-3">
                        <div className="card-body">
                        <form onSubmit={this.storeImage}>
        
                            <h6 className="h6 font-monospace">Photo de profil</h6>
                            <div className="">
                                <label>Photo</label>
                                <input type={"file"} className="form-control form-control-sm" name="file" onChange={this.handleInputFile} />
                            </div>
                            
                            <div className=" pt-2 text-center">
                                <span className="text-danger"> { this.state.errorImg }</span>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={this.state.btn} type='submit' >Modifier la photo</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center">
                    <div className="col-md-9">
                        <div className="card shadow overflow-hidden mb-3">
                            <div className="card-body row">
                                <h6 className="h4 font-monospace">Profil</h6>
                                <span className="text-primary text-small">{this.state.valid}</span>
                                <div className="col-md-6 mt-2">
                                    <label>Nom</label>
                                    <input type={"text"} className="form-control form-control-sm" required={true} name="name" value={ this.state.name} onChange={this.hundelInput} />
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label>Adresse</label>
                                    <input type={"text"} className="form-control form-control-sm" required={true} name="adress" value={ this.state.adress} onChange={this.hundelInput} />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Téléphone</label>
                                    <input type={"number"} className="form-control form-control-sm" required={true} name="tel" value={ this.state.tel} onChange={this.hundelInput} />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Email </label>
                                    <input type={"email"} className="form-control form-control-sm" required={true} name="email" value={ this.state.email} onChange={this.hundelInput} />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Genre</label>
                                    <select name="gender" value={ this.state.gender} onChange={ this.hundelInput} className="form-control form-control-sm">
                                        <option>.....</option>
                                        <option value={"Masculin"}>Masculin</option>
                                        <option value={"Feminin"}>Feminin</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Date de naissance</label>
                                    <input type={"date"} name="date_of_birth" value={ this.state.date_of_birth} onChange={ this.hundelInput} className="form-control form-control-sm" />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Nouveau mot de passe</label>
                                    <input type={"password"} className="form-control form-control-sm" required={true} name="password" onChange={this.hundelInput} />
                            </div>
                                <div className="col-md-4 mt-2">
                                    <label>Confirmer le mot de passe</label>
                                    <input type={"password"} className="form-control form-control-sm" required={true} name="confirmed" onChange={this.hundelInput} />
                                </div>
                                <div className=" pt-2 text-end">
                                    <span className="text-danger text-small">{this.state.error}</span>
                                    <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={this.state.btn}  onClick={() => this.storeProfile()}>Actualiser</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="card shadow overflow-hidden mb-3">
                            <div className="card-body row">
                                <h6 className="h4 fw-700 font-monospace">Connaissance</h6>
                                <span className="text-primary text-small">{this.state.valid}</span>
                                <div className="col-md-6 mt-2">
                                    <label>Titre du projet</label>
                                    <textarea name="project_title" value={ this.state.project_title} className="form-control form-control-sm" rows={2}  onChange={this.hundelInput}></textarea>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label>Impact du projet sur la communauté</label>
                                    <textarea name="project_impact" value={ this.state.project_impact} className="form-control form-control-sm" rows={2}  onChange={this.hundelInput}></textarea>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Langages de programmation</label>
                                    <textarea name="programming_language" value={ this.state.programming_language} className="form-control form-control-sm" rows={2}  onChange={this.hundelInput}></textarea>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Frameworks backend</label>
                                    <textarea name="frameworks_backend" value={ this.state.frameworks_backend} className="form-control form-control-sm" rows={2}  onChange={this.hundelInput}></textarea>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Frameworks frontend</label>
                                    <textarea name="Frameworks_frontend" value={ this.state.Frameworks_frontend} className="form-control form-control-sm" rows={2}  onChange={this.hundelInput}></textarea>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label>Votre profil GitHub</label>
                                    <input type={"link"} className="form-control form-control-sm" required={true} name="github" value={ this.state.github} onChange={this.hundelInput} />
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label>Plateformes d'hebergement (cloud) </label>
                                    <input type={"text"} className="form-control form-control-sm" required={true} name="cloud" value={ this.state.cloud} onChange={this.hundelInput} />
                                </div>
                                
                                <div className=" pt-2 text-center">
                                    <span className="text-danger text-small">{this.state.error1}</span>
                                    <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={this.state.btn}  onClick={() => this.storeApplication()}>Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}
export default Profil;