import axios from "axios";
import React, {Component} from "react";
import Register from "./Register";

class Login extends Component{

    state = {
        username : "",
        password : "",
        error : "",
        registe : false,
        click : false
    }
    componentDidMount(){
        window.localStorage.clear();
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    createRegister = () =>{
        this.setState({ registe : !this.state.registe})
    }

    getLogin  = () => {

        this.setState({
            click : true,
            error : ""
        })
         axios.post("login", {
            username: this.state.username,
            password: this.state.password
        }).then((login) => {
            localStorage.setItem("user", JSON.stringify(login.data.data));
            localStorage.setItem("permissions", JSON.stringify(login.data.permissions) );
            window.location.replace(`https://salondigital.fondationmvura.org`);

        }).catch((error) => {
            window.localStorage.clear();
            this.setState({
                error: error.response.data.msg,
            });
        });
        
    }

    render(){
        if(this.state.registe) {
            return(
                 <Register />
            )
        }

        if(!this.state.registe) {
            return(
                <div id="body" className="auth-page">
            {/* Log In page */}
                <div className="container-md">
                    <div className="row vh-100 d-flex justify-content-center">
                        <div className="col-12 align-self-center">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4 mx-auto">
                                        <div className="card shadow mb-4 rounded rounded-3">
                                            <img src="/photo/backgrnd.jpeg" className="" />
                                            <div className="card-body pt-0">                                    
                                                <form className="my-4">
                                                    {this.state.error && (<div className="alert alert-danger text-center text-danger py-1 px-2 text-small">{this.state.error}</div>)}            
                                                    <div className="form-group mb-2">
                                                        <label className="form-label" htmlFor="username">Téléphone ou email</label>
                                                        <input type="text" className="form-control" id="username" name="username" onChange={this.handleInput}  placeholder="" />                               
                                                    </div>{/*end form-group*/} 
                        
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="userpassword">Mot de passe</label>                                            
                                                        <input type="password" className="form-control" name="password"  onChange={this.handleInput}   id="userpassword" placeholder="" />                            
                                                    </div>{/*end form-group*/} 
                        
                                                    <div className="form-group mb-0 row">
                                                        <div className="col-12">
                                                            <div className="d-grid mt-3">
                                                                <button className="btn btn-primary" type="button" onClick={ () => this.getLogin() }>Connexion</button>
                                                            </div>
                                                        </div>{/*end col*/} 
                                                    </div>                        
                                                </form>{/*end form*/}
                                                <div className="m-3 text-center text-muted">
                                                    <p className="mb-0">Vous n'etes pas inscrit ?  
                                                    {/* <a href="#"  className="text-primary ms-2" onClick={() => this.createRegister()}>Créer un compte</a> */}
                                                    </p>
                                                </div>
                                                <hr className="hr-dashed mt-4" />
                                                <div className="text-center mt-n5">
                                                    <h6 className="card-bg px-3 my-4 d-inline-block" type="button" onClick={() => this.createRegister()}>Iscrivez vous</h6>
                                                    {/* <h6 className="card-bg px-3 my-4 d-inline-block">Connectez vous avec</h6> */}
                                                </div>
                                            </div>{/*end card-body*/}
                                        </div>{/*end card*/}
                                    </div>{/*end col*/}
                                </div>{/*end row*/}
                            </div>{/*end card-body*/}
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>{/*end container*/}  
            </div>
            )
        }
    }
}
export default Login