import React, {Component} from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class Dashboard extends Component{

    state = {
        loading : false,
        isModal : false,
        user : {},
        permissions : [],
    }

    componentDidMount(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
       
        if(user.id){
            this.getUser();
            this.permission();
            this.refreshToken();
        }
    }


    async getUser(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var data = await axios.get("users/" + user.id);
 
        if(data.status === 200){
            this.setState({
                user : data.data
            });
        }
    }

    async refreshToken () {
        try{
            var token = await axios.get('token');
            if(token.status == 200)
            {
                var user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(`https://salondigital.fondationmvura.org/login`)
            }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(`https://salondigital.fondationmvura.org/login`)
        }
    }

    async permission(){
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions"))  : [] ;
        
        if(permissions.length == 0)
        {
            var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
            
            var permissionDate = await axios.get("permissions/user/" + user.id);
            
            if(permissionDate.status == 200)
            {
                this.setState({
                    permissions : permissionDate.data.permissions
                });
                localStorage.setItem("permissions", JSON.stringify(permissionDate.data.permissions));
            }
        }
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

     handleCheck(val) {
        this.permission();

        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : this.state.permissions;
 
        return permissions.some(item => val === item.name)
    }


    render(){
  

        return(
            <div>
            <h4 className="text-center font-monospace mt-3">PROJET «SALON DIGITAL» EDITION 2022</h4>
                <div className="row m2-4 pt-4">
                
                    {
                        this.state.user.id && (
                            <div className="col-md-6">
                                <div className="card shadown mb-3 rounded rounded-3">
                                    <div className="card-body">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <Link to={'#'}  className="text-decoration-none">
                                                    <img src={ this.state.user.avatar } className="rounded rounded-3 img-fluid me-2" width={(  this.state.user.avatar) && ("150px")} />
                                                </Link>
                                            </div>
                                            <div class="flex-grow-1 ms-3 text-truncate">
                                                <div><a href={  this.state.user.request.github  } rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <i className="ti ti-settings"></i> {  this.state.user.request.github ? this.state.user.request.github : "GitHub"   }</a> </div>
                                                <div className="text-truncate"><h4 className="h4 text-primary"> <i className="ti ti-briefcase text-primary fw-600 font-15"></i> {  this.state.user.request.project_title  ? this.state.user.request.project_title  : "Projet..." }</h4></div>
                                                <div className="text-truncate"><i className="ti ti-activity text-primary fw-500 font-15"></i> {  this.state.user.request.project_impact ? this.state.user.request.project_impact  : "Impact du projet..." }</div>
                                                <div className="text-truncate"><i className="ti ti-heart text-danger font-15"></i> {  this.state.user.request.status  }</div>
                                                <div className="mt-3"> <Link to={"/profile"} className="text-primary  border border-primary rounded rounded-3 px-1">Actualiser</Link> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="col-md-6">
                        <div className="card shadown mb-3 rounded rounded-3">
                            <div className="card-body">
                                <div>
                                    <b className="font-16">C</b>onformément à sa mission première, <a href="https://fondationmvura.org" className="text-decoration-none text-primary"><b>Fondation MVURA</b></a> œuvre pour Promotion des TIC au Burundi
                                     pour atteindre le Développement Durable.<br /> C’est dans ce contexte qu’elle vient d’initier un projet dénommé <b>« SALON DIGITAL »</b>, l’évènement qui se tiendra chaque année pour la collecte des meilleures innovations du monde entier qui répondent aux problèmes de la société de manière inventive et ambitieuse.<br />
                                     Et ainsi permettre aux professionnels du métier de pouvoir saisir toutes les opportunités offertes par les TIC.<br /> 
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadown mb-3 rounded rounded-3">
                            <div className="card-body">
                                <div>
                                <b className="font-16">L</b>’activité se déroulera en deux phases pour atteindre les objectifs dudit salon.<br />
                                 Il s’agit d’un Séminaire de deux jours à l’endroit des universitaires et un salon digital aux professionnels des métiers et tous ceux qui sont sensible au noble secteur pour deux jours<br /><br />
                                <b>>></b> Quant au Séminaire, l’objectif principal de cette activité est de stimuler les universitaires à développer en eux, l’esprit de créativité, en vue de lutter contre le chômage et ainsi éviter la tendance d’émigration et enfin maîtriser les productions intellectuelles locales.<br />
                                Puisqu’il y aura des <b>exposés sur les métiers informatiques</b> et des <b>témoignages</b> sur les bienfaits des TIC par le Président Fondateur. 
                                Les start-up seront motivés et encouragés,  car ce domaine pourrait aider la jeunesse burundaise à se développer par l’accès aux opportunités d’affaires offertes par les TIC partout dans le monde.<br /><br />
                                <b>>></b> Il y aura <b>un concours des génies logiciels</b> où chaque étudiant aura droit à la  présentation de son projet innovateur.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadown mb-3 rounded rounded-3">
                            <div className="card-body">
                                <h4>Les temps forts du salon digital</h4>
                                <b className="font-16"> I. Des Expositions</b> Des stands de formats divers seront aménagés pour les acteurs du secteur informatique, services connexes et toute autre partie prenante concernée. <br />
                                <b className="font-16"> II. Des conférences</b> Les participants auront droit aux conférences -débats consacrés aux thématiques en rapport avec le thème central. Ils seront animés par des responsables d’Institutions gouvernementales et non gouvernementales, les Chefs d’Entreprises et des Experts de renommé.<br />
                                
                                <b className="font-16"> III. Un panel de haut niveau</b> sera organisé à l’intention des représentants du Gouvernement, des Ministères, des Ambassades et Consulats, des Organisations des Partenaires Techniques et Financiers, des Institutions Publiques et Privées, des start-ups, des Grandes et Moyennes Entreprises, locales et régionales, et toute autre institution intéressée.<br />
                                
                                <b className="font-16"> IV. Une soirée de gala</b> Un dîner d’affaire sera organisé. Il est, une fois de plus, un cadre de rencontre autour d’un banquet qui mettra en relation directe d’affaires les convives. L’objectif est de faciliter les contacts d’affaires et de créer des opportunités de rencontres B to B entre les professionnels du métier et les partenaires techniques et financiers.<br />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default Dashboard;