import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";

class Navbar extends Component{

    logout = (e) => {
        axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
        window.location.replace(`https://salondigital.fondationmvura.org/login`)
    }

    render(){
      var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
      
        return(
          <div className="topbar bg-nav">            
          {/* Navbar */}
          <nav className="navbar-custom bg-nav" id="navbar-custom">    
              <ul className="list-unstyled topbar-nav float-end mb-0">
                 

                  {
                    !user.avatar && (
                    <li className="dropdown notification-list">
                        <Link className="nav-link text-white border rounded rounded-3 p-1"  to="/login" role="button"
                            aria-haspopup="false" aria-expanded="false">
                              connexion
                        </Link>
                    </li>
                  )}

                  
                    
              </ul>{/*end topbar-nav*/}

              <ul className="list-unstyled topbar-nav mb-0">                        
                  
                    <li>
                      <Link to="/" className="nav-link" >
                      <img src="/photo/logo2.jpeg" className="w-100" />
                      </Link>
                    </li>
                    
                    <li>
                      <Link to="/applications" className="nav-link text-white border rounded rounded-3 p-1" >
                        Participants
                      </Link>
                    </li>

                    <li>
                      <Link to="/results" className="nav-link text-white border rounded rounded-3 p-1" >
                        Resultats
                      </Link>
                    </li>

                    {
                    user.avatar && (
                    <li className="dropdown">
                        <Link className="nav-link dropdown-toggle nav-user" data-bs-toggle="dropdown" to="#" role="button"
                            aria-haspopup="false" aria-expanded="false">
                            <div className="d-flex align-items-center text-truc text-white">
                                <img src={ user.avatar } alt={ user.name} title={ user.name} className="rounded-circle me-2 thumb-sm" />
                                <div>
                                    {/* <small className="d-none d-md-block font-11">{ user.userrole ? user.userrole.role ? user.userrole.role.name : "" : "" }</small> */}
                                    <span className="d-none d-md-block fw-semibold font-12"><i
                                            className="mdi mdi-chevron-down"></i></span>
                                </div>
                            </div>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                            <div className="dropdown-divider mb-0"></div>
                            <Link className="dropdown-item" to="#" onClick={() => this.logout() }>  Déconnexion</Link>
                        </div>
                    </li>
                    )}
                                       
              </ul>
          </nav>
          {/* end navbar*/}
      </div>
        )
    }
}
export default Navbar;