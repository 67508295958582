import React, {Component} from "react";

class Footer extends Component{

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);
    }
    render(){
        return(
            <div>
                {/* Footer Start */}
                <footer className="footer text-center text-sm-start">
                    &copy; Salon digital 
                </footer>
            </div>
        )
    }
}
export default Footer;