import React, {Component} from "react";

class Participant extends Component{

    render(){

        return(
          <div>
             Participant

      </div>
        )
    }
}
export default Participant;
