import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouterPage from "./RouterPage";
import Footer from "./initials/Footer";
import Navbar from './initials/Navbar';

function App() {
  return (
    <div className="row d-flex justify-content-center">
      <div className="col-md-9">
        <Router>
            <Navbar />
            <div className="page-wrapper">
              <div className="page-content-tab">
                <div className="container-fluid">
                  <RouterPage />
                </div>
              </div>
            </div>
            <Footer />
        </Router>
        </div>
    </div>
  );
}

export default App;
